import { useState } from "react"
import styled from "styled-components"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePostNoiseMonitoringPresets } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TPreset } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

type CreatePresetDialogProps = Pick<TMDialogProps, "onClose" | "open"> & {
  presets: TPreset[]
}

export function CreatePresetDialog({
  presets,
  open,
  onClose,
}: CreatePresetDialogProps) {
  if (!open || !presets.length) {
    return null
  }

  return (
    <CreatePresetDialogOpen presets={presets} open={open} onClose={onClose} />
  )
}

function CreatePresetDialogOpen({
  presets,
  open,
  onClose,
}: CreatePresetDialogProps) {
  const { tPlaceholder } = useTranslate()

  const { orgId } = useOrganization()

  const [selectedBasePreset, setSelectedBasePreset] = useState<TPreset>(
    presets[0]
  )
  const [presetName, setPresetName] = useState("")
  const postNoiseMonitoringPresets = usePostNoiseMonitoringPresets({ orgId })

  function handleCreatePreset() {
    if (!selectedBasePreset || !presetName) return

    postNoiseMonitoringPresets.mutate(
      {
        name: presetName,
        copy_from_profile_id: selectedBasePreset.id,
      },
      {
        onSuccess() {
          onClose()
        },
      }
    )
    // TODO MON-737: Redirect to preset edit page when once that view is available
  }

  if (!open || !selectedBasePreset) {
    return null
  }

  return (
    <MDialog
      title={tPlaceholder(`Create a new preset`)}
      description={tPlaceholder(`Settings will be copied from an existing
      preset. You will be able to change them afterwards.`)}
      onConfirm={handleCreatePreset}
      confirmLabel={tPlaceholder("Create")}
      confirmButtonProps={{
        loading: postNoiseMonitoringPresets.isLoading,
        type: "submit",
        form: FORM_ID,
      }}
      onClose={onClose}
      open={open}
      hideClose
    >
      <PresetDialogContainer onSubmit={(e) => e.preventDefault()} id={FORM_ID}>
        <MSelect
          label={tPlaceholder("Based on")}
          value={selectedBasePreset.id}
          options={presets.map((preset) => ({
            label: preset.name,
            value: preset.id,
          }))}
          onChange={(selectedValue: string): void => {
            setSelectedBasePreset(
              presets.find((preset) => preset.id === selectedValue)!
            )
          }}
        />

        <MTextField
          label={tPlaceholder("Preset name")}
          required
          value={presetName}
          onChange={(val) => setPresetName(val)}
        />
      </PresetDialogContainer>
    </MDialog>
  )
}

const PresetDialogContainer = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const FORM_ID = "create-monitoring-preset-form"
