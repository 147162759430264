import { useState } from "react"

import { CreatePresetDialog } from "src/components/SettingsProfiles/MonitoringPresets/CreateNoiseMonitoringPresetDialog"
import { NoiseMonitoringPresetTable } from "src/components/SettingsProfiles/MonitoringPresets/NoiseMonitoringPresetTable"
import { useMonitoringPresetBreadcrumbs } from "src/components/SettingsProfiles/MonitoringPresets/useMonitoringPresetBreadcrumbs"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchNoiseMonitoringPresets } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"

type NoiseMonitoringPresetsProps = { hidden?: boolean }

export function NoiseMonitoringPresets({
  hidden,
}: NoiseMonitoringPresetsProps) {
  const { tPlaceholder } = useTranslate()

  const { orgId } = useOrganization()

  const breadcrumbs = useMonitoringPresetBreadcrumbs()

  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets({ orgId })
  const profiles = fetchNoiseMonitoringPresets.data?.profiles

  const [createPresetDialogOpen, setCreatePresetDialogOpen] = useState(false)

  if (hidden) {
    return null
  }

  return (
    <BreadcrumbView
      breadcrumbs={breadcrumbs.noise}
      title={tPlaceholder("Monitoring presets")}
      description={tPlaceholder(
        "Tweak your thresholds, quiet hours and noise alerts."
      )}
      actionBar={
        <MButton
          onClick={() => setCreatePresetDialogOpen(true)}
          variant="secondary"
        >
          {tPlaceholder("Create a new preset")}
        </MButton>
      }
    >
      <NoiseMonitoringPresetTable
        presets={fetchNoiseMonitoringPresets.data?.profiles}
      />

      {profiles && (
        <CreatePresetDialog
          presets={profiles}
          open={createPresetDialogOpen}
          onClose={() => setCreatePresetDialogOpen(false)}
        />
      )}
    </BreadcrumbView>
  )
}
